import React from 'react';
import '../styles/faqstyle.css';
import Navbar from '../components/Navbar'; // Import the Navbar component

const FAQ = () => {
    const handleClick = (event) => {
        const faqItem = event.currentTarget;
        faqItem.classList.toggle('active');
        const answer = faqItem.querySelector('.faq-answer');
        if (faqItem.classList.contains('active')) {
            answer.style.maxHeight = answer.scrollHeight + 'px';
        } else {
            answer.style.maxHeight = 0;
        }
    };

    return (
        <div>
            <Navbar /> {/* Use Navbar component */}
            <section className="content">
                <h1 className="faq-title">FAQs</h1>
                <div className="faq-container">
                    <div className="faq-item" onClick={handleClick}>
                        <div className="faq-question">What is Wits Verse?</div>
                        <div className="faq-answer">WitsVerse is an online platform for skill-based gaming tournaments. Players can join competitions, earn rewards, and use our exclusive cryptocurrency to enhance their gaming experience.</div>
                    </div>
                    <div className="faq-item" onClick={handleClick}>
                        <div className="faq-question">How Do I Participate In A Tournament On Wits Verse?</div>
                        <div className="faq-answer">To participate, visit the WitsVerse website and connect your digital wallet. Browse the available tournaments, select one that interests you, and you're ready to compete!</div>
                    </div>
                    <div className="faq-item" onClick={handleClick}>
                        <div className="faq-question">What Is The Wits Verse Cryptocurrency, And How Is It Used?</div>
                        <div className="faq-answer">The WitsVerse cryptocurrency is our digital currency used on the platform. You can use it to enter tournaments, purchase in-game items, and unlock special features. Earn cryptocurrency through gameplay or purchase it directly on our website. Contract Address: 0x9a71fFC93aEF48068Bbdf1C091B6877180fAb366</div>
                    </div>
                    <div className="faq-item" onClick={handleClick}>
                        <div className="faq-question">Is WitsVerse Compatible With All Web Browsers?</div>
                        <div className="faq-answer">WitsVerse is designed to work with all major web browsers, including Chrome, Firefox, Safari, and Edge. For optimal performance, ensure your browser is up to date.</div>
                    </div>
                    <div className="faq-item" onClick={handleClick}>
                        <div className="faq-question">How Can I Contact Support If I Encounter Any Issues On The Website?</div>
                        <div className="faq-answer">For support, please email us at contact@witsverse.com. Our team will assist you as soon as possible.</div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default FAQ;
