import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { useNavigate, useParams } from 'react-router-dom';
import {
  BettingSystemAddress,
  BettingSystemABI,
  WVTTokenAddress,
  WVTTokenABI,
} from '../utils/constants.js';
import '../styles/payments.css'; // Import the CSS file
import '../styles/styles.css';
import Navbar from '../components/Navbar'; // Import the Navbar component
import CustomAlert from '../components/CustomAlert'; // Import your custom alert component

const PaymentsPage = () => {
  const { lobby_id } = useParams(); // Get lobby_id from the URL
  const [lobby, setLobby] = useState(null); // State to store lobby details
  const [bettingContract, setBettingContract] = useState(null);
  const [wvtContract, setWvtContract] = useState(null);
  const [timeLeft, setTimeLeft] = useState(300); // Timer state in seconds (5 minutes)
  const [account, setAccount] = useState(null); // State to store connected wallet address
  const [provider, setProvider] = useState(null); // State to store Ethereum provider
  const [processing, setProcessing] = useState(false); // State to manage processing state
  const [alertMessage, setAlertMessage] = useState(''); // State for alert message
  const [showAlert, setShowAlert] = useState(false); // State to control alert visibility
  const navigate = useNavigate();

  useEffect(() => {
    // Initialize Ethereum provider and connect wallet
    const initializeWallet = async () => {
      if (window.ethereum) {
        try {
          const ethereumProvider = new ethers.providers.Web3Provider(window.ethereum);
          setProvider(ethereumProvider);

          const accounts = await ethereumProvider.send('eth_requestAccounts', []);
          setAccount(accounts[0]);

          const signer = ethereumProvider.getSigner();
          setBettingContract(new ethers.Contract(BettingSystemAddress, BettingSystemABI, signer));
          setWvtContract(new ethers.Contract(WVTTokenAddress, WVTTokenABI, signer));
        } catch (error) {
          console.error('Error initializing wallet:', error);
          showCustomAlert('Error initializing wallet. See console for details.');
        }
      } else {
        showCustomAlert('Please install MetaMask or another Ethereum wallet.');
      }
    };

    initializeWallet();
  }, []);

  useEffect(() => {
    const fetchLobby = async () => {
      try {
        const response = await fetch(`https://e2mrgwhsw2.execute-api.ap-south-1.amazonaws.com/dev/lobby/${lobby_id}`);
        if (!response.ok) {
          throw new Error(`Failed to fetch lobby. Status: ${response.status}`);
        }
        const data = await response.json();
        setLobby(data); // Store lobby details in state
      } catch (error) {
        console.error('Error fetching lobby:', error);
        showCustomAlert('Error fetching lobby. See console for details.');
      }
    };

    fetchLobby();
  }, [lobby_id]);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timerInterval);
          showCustomAlert('Time expired! Resetting the lobby and redirecting.');
          handleLobbyReset(); // Reset the lobby when the timer expires
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => {
      clearInterval(timerInterval);
      handleLobbyReset(); // Reset the lobby when the component unmounts
    };
  }, [navigate]);

  const handleLobbyReset = async () => {
    if (!lobby || !account) return;

    try {
      const resetResponse = await fetch('https://e2mrgwhsw2.execute-api.ap-south-1.amazonaws.com/dev/reset-lobby', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ lobby_id, player_id: account }),
      });

      if (!resetResponse.ok) {
        const resetData = await resetResponse.json();
        throw new Error(resetData.error || 'Failed to reset the lobby.');
      }

      showCustomAlert('Lobby reset successfully.');
      navigate('/lobby');
    } catch (error) {
      console.error('Error resetting lobby:', error);
      showCustomAlert(`Error: ${error.message}`);
    }
  };

  const handlePayment = async () => {
    if (processing || !lobby || !account || !provider) return;

    setProcessing(true);

    try {
      const amountInEther = ethers.utils.parseEther(lobby.amount.toString());
      let tx;

      if (lobby.currency === 'MATIC') {
        tx = await bettingContract.placeBet(amountInEther, { value: amountInEther });
      } else if (lobby.currency === 'WVT') {
        const amountInWei = ethers.utils.parseUnits(lobby.amount.toString(), 18);
        const allowance = await wvtContract.allowance(account, BettingSystemAddress);

        if (allowance.lt(amountInWei)) {
          const approveTx = await wvtContract.approve(BettingSystemAddress, amountInWei);
          await approveTx.wait();
        }

        tx = await bettingContract.placeBet(amountInWei, { value: 0 });
      }

      await tx.wait();
      showCustomAlert('Payment successful!');

      const lockResponse = await fetch('https://e2mrgwhsw2.execute-api.ap-south-1.amazonaws.com/dev/lock-lobby', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ lobby_id: lobby.lobby_id, player_id: account }),
      });

      if (!lockResponse.ok) {
        const lockData = await lockResponse.json();
        throw new Error(lockData.error || 'Failed to lock the lobby.');
      }

      showCustomAlert('Lobby locked successfully! Game is starting.');
      navigate(`/game/${lobby_id}`);
    } catch (error) {
      console.error('Error completing payment:', error);
      showCustomAlert(`Error: ${error.message}`);
    } finally {
      setProcessing(false);
    }
  };

  const handleCollapse = () => {
    handleLobbyReset();
  };

  const shortenCreatorId = (id) => id ? `${id.slice(0, 6)}...${id.slice(-4)}` : '';

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  const showCustomAlert = (message) => {
    setAlertMessage(message);
    setShowAlert(true);
    setTimeout(() => setShowAlert(false), 3000); // Alert disappears after 3 seconds
  };

  return (
    <div className="payments-page">
      <Navbar />
      {showAlert && <CustomAlert message={alertMessage} onClose={() => setShowAlert(false)} />} {/* Conditionally render CustomAlert */}
      <button className="collapse-button" onClick={handleCollapse} disabled={processing}>Collapse</button>
      <div className="timer">
        {formatTime(timeLeft)}
      </div>
      {lobby ? (
        <div className="payments-container">
          <img src="../assets/landscape.png" alt="Landscape" className="landscape-image" />
          <button className="pay-button" onClick={handlePayment} disabled={processing}>Pay</button>
          <div className="info-cards-container">
            <div className="amount-card">
              <p><strong>Amount:</strong> {lobby.amount}</p>
            </div>
            <div className="currency-card">
              <p><strong>Currency:</strong> {lobby.currency}</p>
            </div>
            <div className="creator-id-card">
              <p><strong>Creator ID:</strong> {shortenCreatorId(lobby.creator_id)}</p>
            </div>
          </div>
        </div>
      ) : (
        <p>Loading lobby details...</p>
      )}
    </div>
  );
};

export default PaymentsPage;
