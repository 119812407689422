import React from 'react';
import '../styles/styles.css'; 
import '../styles/playstyle.css'; 
import game1 from "../assets/game1.png"
import game2 from "../assets/game2.jpg"
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import Navbar from '../components/Navbar'; // Import the Navbar component


const Play = () => {
    const navigate = useNavigate(); // Initialize navigate function

    // Function to redirect to Lobby
    const redirectToLobby = () => {
        navigate('/Lobby');
    };

    return (
        <div>
            <Navbar /> {/* Use the Navbar component here */}

            <section className="wits-arena">
                <h2>WITS ARENA</h2>
                <div className="game-cards">
                    <div className="card">
                        <h3>ㅤ</h3>
                        <img src={game1} alt="Game 1" className="game-image" />
                        <button className="btn-play" onClick={redirectToLobby}>Play Now</button>
                    </div>
                    <div className="card">
                        <h3>ㅤ</h3>
                    <img src={game2} alt="Game 1" className="game-image" />
                    </div>
                    <div className="card">
                        <h3>ㅤ</h3>
                        <img src={game2} alt="Game 1" className="game-image" />
                        
                    </div>
                </div>
            </section>
            
        </div>
    );
};

export default Play;
