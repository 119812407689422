import React, { useState, useEffect, useContext } from 'react';
import { ethers } from 'ethers';
import { useNavigate } from 'react-router-dom';
import { WalletContext } from '../wallet'; // Import WalletContext
import Navbar from '../components/Navbar'; // Import the Navbar component
import CustomAlert from '../components/CustomAlert'; // Import the CustomAlert component
import '../styles/claimstyle.css';
import leftArrow from '../assets/left.png';
import rightArrow from '../assets/right.png';
import wvtImage from '../assets/wvt.png';
import maticImage from '../assets/matic.png';

const ClaimPage = () => {
    const { walletAddress, connectWallet } = useContext(WalletContext); // Access walletAddress and connectWallet from context
    const [unclaimedWins, setUnclaimedWins] = useState([]);
    const [loading, setLoading] = useState(false);
    const [disabledButtons, setDisabledButtons] = useState({});
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 1;
    const [alertMessage, setAlertMessage] = useState(''); // Manage the alert message state
    const navigate = useNavigate();

    const fetchUnclaimedWins = async (address) => {
        setLoading(true);
        try {
            const response = await fetch(`https://e2mrgwhsw2.execute-api.ap-south-1.amazonaws.com/dev/get-unclaimed-wins?wallet_address=${address}`);
            const data = await response.json();
            if (data.success) {
                setUnclaimedWins(data.unclaimed_wins || []);
            } else {
                setAlertMessage(`Error fetching unclaimed wins: ${data.message}`);
            }
        } catch (error) {
            setAlertMessage('Error fetching unclaimed wins.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const checkWalletConnection = async () => {
            if (window.ethereum) {
                try {
                    const provider = new ethers.providers.Web3Provider(window.ethereum);
                    const signer = provider.getSigner();
                    const address = await signer.getAddress();
                    fetchUnclaimedWins(address); // Fetch unclaimed wins after getting the address
                } catch (error) {
                    setAlertMessage('Wallet not connected');
                }
            } else {
                setAlertMessage('Please install MetaMask or another Ethereum wallet.');
                navigate('/');
            }
        };

        checkWalletConnection();
    }, [navigate]);

    const handleClaim = async (lobbyId, amount, currency) => {
        setDisabledButtons((prevState) => ({ ...prevState, [lobbyId]: true }));

        try {
            const response = await fetch('https://e2mrgwhsw2.execute-api.ap-south-1.amazonaws.com/dev/process-winner', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    winnerAddress: walletAddress,
                    lobbyId: lobbyId,
                    betAmount: amount,
                    currency: currency,
                }),
            });

            const data = await response.json();
            if (data.success) {
                setAlertMessage('Claim successful! Transaction has been processed.');
                fetchUnclaimedWins(walletAddress); // Refresh the unclaimed wins
            } else {
                setAlertMessage(`Claim failed: ${data.message}`);
            }
        } catch (error) {
            setAlertMessage('An error occurred while processing your claim.');
            console.error("Fetch error:", error);
        } finally {
            setDisabledButtons((prevState) => ({ ...prevState, [lobbyId]: false }));
        }
    };

    const handleNextPage = () => {
        if ((currentPage + 1) * itemsPerPage < unclaimedWins.length) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 0) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    };

    const paginatedWins = unclaimedWins.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

    // Clear the alertMessage when CustomAlert closes
    const handleAlertClose = () => {
        setAlertMessage(''); // Clear the alert message
    };

    return (
        <div className="claim-page-container">
            <Navbar connectWallet={connectWallet} walletAddress={walletAddress} /> {/* Use the Navbar component */}
            <h1>Claim Your Prize</h1>

            {alertMessage && (
                <CustomAlert
                    message={alertMessage}
                    onClose={handleAlertClose} // Handle alert close event
                />
            )}

            {!walletAddress ? (
                <p>Please connect your wallet to view and claim your prizes.</p>
            ) : loading ? (
                <p>Loading your unclaimed wins...</p>
            ) : (
                <>
                    {unclaimedWins.length > 0 ? (
                        <div className="card-style">
                            <div className="navigation-buttons">
                                <div className="nav-btn prev-btn" onClick={handlePrevPage}>
                                    <img 
                                        src={leftArrow} 
                                        alt="Previous" 
                                        className="nav-img" 
                                        style={{ cursor: currentPage === 0 ? 'default' : 'pointer' }}
                                    />
                                </div>
                                <div className="unclaimed-wins-content">
                                    {paginatedWins.map((win) => (
                                        <div className="win-item" key={win.lobby_id}>
                                            <div className="currency-container">
                                                {win.currency.toLowerCase() === 'wvt' && (
                                                    <img src={wvtImage} alt="WVT" className="currency-image" />
                                                )}
                                                {win.currency.toLowerCase() === 'matic' && (
                                                    <img src={maticImage} alt="MATIC" className="currency-image" />
                                                )}
                                            </div>
                                            <p><strong>Lobby ID:</strong> {win.lobby_id.slice(0, 6)}...{win.lobby_id.slice(-4)}</p>
                                            <p><strong>Opponent Wallet:</strong> {win.opponent_wallet ? win.opponent_wallet.slice(0, 6) + '...' + win.opponent_wallet.slice(-4) : 'N/A'}</p>
                                            <p><strong>Bet Amount:</strong> {win.amount} {win.currency.toUpperCase()}</p>
                                            <button 
                                                className="claim-button"
                                                onClick={() => handleClaim(win.lobby_id, win.amount, win.currency)} 
                                                disabled={disabledButtons[win.lobby_id]}
                                            >
                                                {disabledButtons[win.lobby_id] ? 'Processing...' : 'Claim Prize'}
                                            </button>
                                        </div>
                                    ))}
                                </div>
                                <div className="nav-btn next-btn" onClick={handleNextPage}>
                                    <img 
                                        src={rightArrow} 
                                        alt="Next" 
                                        className="nav-img" 
                                        style={{ cursor: (currentPage + 1) * itemsPerPage >= unclaimedWins.length ? 'default' : 'pointer' }}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <p>You have no unclaimed wins.</p>
                    )}
                </>
            )}
        </div>
    );
};

export default ClaimPage;
