// Navbar.js
import React, { useContext, useState } from 'react';
import '../styles/navstyle.css';
import logo from '../assets/logo.png';
import { WalletContext } from '../wallet';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { walletAddress, connectWallet, disconnectWallet } = useContext(WalletContext);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="navbar">
      <div className="container">
        <div className="logo">
          <img src={logo} alt="Logo" />
        </div>
        <div className="hamburger" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <ul className={`nav-links ${menuOpen ? 'open' : ''}`}>
          <li><a href="/">Home</a></li>
          <li><a href="/Play-Page">Play</a></li>
          <li><a href="/Rules-Page">Rules</a></li>
          <li><a href="/Faq-Page">FAQ</a></li>
          <li><a href="/Claim-Page">Claim</a></li>
        </ul>

        <div className="wallet-section">
          <div className="wallet-button">
            {!walletAddress ? (
              <button onClick={connectWallet} className="btn-connect-wallet">
                Connect Wallet
              </button>
            ) : (
              <span className="wallet-address" onClick={disconnectWallet}>
                {`${walletAddress.slice(0, 6)}...${walletAddress.slice(-4)}`}
              </span>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
