import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { WalletProvider } from "./wallet"; // Make sure the import path is correct
import Home from "./pages/Home";
import FAQ from "./pages/FAQ";
import Play from "./pages/Play";
import Rules from "./pages/Rules";
import Lobby from "./pages/Lobby";
import BettingPage from "./pages/BettingPage";
import GamePage from "./pages/GamePage";
import ClaimPage from "./pages/ClaimPage";
import PaymentsPage from "./pages/PaymentsPage";

function App() {
  return (
    <WalletProvider>
      {" "}
      {/* Wrap your app with WalletProvider */}
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Faq-Page" element={<FAQ />} />
          <Route path="/Play-Page" element={<Play />} />
          <Route path="/Rules-Page" element={<Rules />} />
          <Route path="/Lobby" element={<Lobby />} />
          <Route path="/Create-Lobby" element={<BettingPage />} />
          <Route path="/Payments/:lobby_id" element={<PaymentsPage />} />
          <Route path="/Game/:lobby_id" element={<GamePage />} />
          <Route path="/Claim-Page" element={<ClaimPage />} />
        </Routes>
      </Router>
    </WalletProvider>
  );
}

export default App;
