import React, { useState, useEffect, useContext } from 'react';
import { ethers } from 'ethers';
import { v4 as uuidv4 } from 'uuid';
import { BettingSystemAddress, BettingSystemABI, WVTTokenAddress, WVTTokenABI } from '../utils/constants.js';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar'; 
import CustomAlert from '../components/CustomAlert'; 
import { WalletContext } from '../wallet'; 
import '../styles/lobbystyle.css';
import '../styles/bettingstyle.css';

const BettingPage = () => {
  const { walletAddress } = useContext(WalletContext); 
  const [account, setAccount] = useState(walletAddress || '');
  const [bettingContract, setBettingContract] = useState(null);
  const [wvtContract, setWvtContract] = useState(null);
  const [betAmount, setBetAmount] = useState('');
  const [betToken, setBetToken] = useState('MATIC');
  const [choices, setChoices] = useState({
    choice1: 'stone',
    choice2: 'stone',
    choice3: 'stone',
    choice4: 'stone',
    choice5: 'stone',
    choice6: 'stone',
  });
  const [step, setStep] = useState(1);
  const [isBetAmountSelected, setIsBetAmountSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(''); 

  const navigate = useNavigate();

  useEffect(() => {
    const initialize = async () => {
      if (account) {
        try {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          const bettingContract = new ethers.Contract(BettingSystemAddress, BettingSystemABI, signer);
          const wvtContract = new ethers.Contract(WVTTokenAddress, WVTTokenABI, signer);
          setBettingContract(bettingContract);
          setWvtContract(wvtContract);
        } catch (error) {
          console.error('Error initializing contracts:', error);
          setAlertMessage('Error initializing contracts. See console for details.');
        }
      } else {
        setAlertMessage('Please install MetaMask or another Ethereum wallet.');
      }
    };

    initialize();
  }, [account]);

  const handleConnectWallet = () => {
    if (walletAddress) {
      setAccount(walletAddress);
      setAlertMessage('Wallet connected successfully!');
    } else {
      setAlertMessage('Error connecting wallet. See console for details.');
    }
  };

  const handleTokenChange = (token) => {
    if (!isBetAmountSelected) {
      setBetToken(token);
    } else {
      setAlertMessage('You cannot switch tokens after selecting a bet amount.');
    }
  };

  const handleBetAmount = (amount) => {
    if (betToken === 'MATIC' && ['1', '10', '100'].includes(amount)) {
      setBetAmount(amount);
      setIsBetAmountSelected(true);
    } else if (betToken === 'WVT' && ['500', '1000'].includes(amount)) {
      setBetAmount(amount);
      setIsBetAmountSelected(true);
    } else {
      setAlertMessage(`Invalid bet amount for ${betToken}.`);
    }
  };

  const handleChoiceChange = (value, index) => {
    setChoices(prevChoices => ({
      ...prevChoices,
      [`choice${index}`]: value,
    }));
    if (index < 6) {
      setStep(index + 1);
    }
  };

  const createLobby = async () => {
    try {
      setLoading(true);
      const amountInEther = ethers.utils.parseEther(betAmount);
      let tx;

      if (betToken === 'MATIC') {
        tx = await bettingContract.placeBet(amountInEther, { value: amountInEther });
      } else if (betToken === 'WVT') {
        const amountInWei = ethers.utils.parseUnits(betAmount, 18);
        const allowance = await wvtContract.allowance(account, BettingSystemAddress);

        if (allowance.lt(amountInWei)) {
          const approveTx = await wvtContract.approve(BettingSystemAddress, amountInWei);
          await approveTx.wait();
        }

        tx = await bettingContract.placeBet(amountInWei, { value: 0 });
      }

      await tx.wait();
      setAlertMessage(`Bet placed with ${betToken} successfully!`);

      const lobbyId = uuidv4();

      const response = await fetch('https://e2mrgwhsw2.execute-api.ap-south-1.amazonaws.com/dev/create-lobby', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          creator_id: account,
          lobby_id: lobbyId,
          currency: betToken,
          amount: betAmount,
          player_id: '',
          status: 'open',
          choice1: choices.choice1,
          choice2: choices.choice2,
          choice3: choices.choice3,
          choice4: choices.choice4,
          choice5: choices.choice5,
          choice6: choices.choice6,
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error(`Failed to create lobby. Response text: ${errorText}`);
        setAlertMessage(`Failed to create lobby. Status: ${response.status}`);
        return;
      }

      setAlertMessage('Lobby created successfully!');
      navigate('/lobby');
    } catch (error) {
      console.error('Error creating lobby:', error);
      setAlertMessage('Error creating lobby. See console for details.');
    } finally {
      setLoading(false);
    }
  };


  return (
    <div className="betting-page">
      <Navbar onConnectWallet={handleConnectWallet} />
      <h1>CREATE A LOBBY</h1>

      {alertMessage && <CustomAlert message={alertMessage} onClose={() => setAlertMessage('')} />}

      <div className="betting-inputs">
        <div className="currency-carousel">
          <button className="carousel-button left" onClick={() => handleTokenChange('MATIC')}>
            <img src="assets/left.png" alt="Left Arrow" />
          </button>
          <div className="currency-image">
            <img src={betToken === 'MATIC' ? 'assets/matic.png' : 'assets/wvt.png'} alt={betToken} />
          </div>
          <button className="carousel-button right" onClick={() => handleTokenChange('WVT')}>
            <img src="assets/right.png" alt="Right Arrow" />
          </button>
        </div>

        <div className="quick-bets">
          {betToken === 'MATIC' && (
            <div className="matic-buttons">
              <button onClick={() => handleBetAmount('1')}>1</button>
              <button onClick={() => handleBetAmount('10')}>10</button>
              <button className="high-bet" onClick={() => handleBetAmount('100')}>100</button>
            </div>
          )}
          {betToken === 'WVT' && (
            <div className="wvt-buttons">
              <button onClick={() => handleBetAmount('500')}>500</button>
              <button className="high-bet" onClick={() => handleBetAmount('1000')}>1000</button>
            </div>
          )}
        </div>

        <div className="form-group">
          <label>Amount: {betAmount} {betToken}</label>
        </div>

        <div className="form-group">
          <label>Select your choices:</label>
          <div className="choices-container">
            {[1, 2, 3, 4, 5, 6].map(index => (
              <div
                key={index}
                className={`choice-step ${step === index ? 'visible' : 'hidden'}`}
              >
                <fieldset>
                  <legend>Choice {index}/6:</legend>
                  <div
                    className={`choice-box ${choices[`choice${index}`] === 'stone' ? 'selected' : ''}`}
                    onClick={() => handleChoiceChange('stone', index)}
                  >
                    <img src="assets/stone.png" alt="Stone" />
                  </div>
                  <div
                    className={`choice-box ${choices[`choice${index}`] === 'paper' ? 'selected' : ''}`}
                    onClick={() => handleChoiceChange('paper', index)}
                  >
                    <img src="assets/paper.png" alt="Paper" />
                  </div>
                  <div
                    className={`choice-box ${choices[`choice${index}`] === 'scissors' ? 'selected' : ''}`}
                    onClick={() => handleChoiceChange('scissors', index)}
                  >
                    <img src="assets/scissors.png" alt="Scissors" />
                  </div>
                </fieldset>
              </div>
            ))}
          </div>
        </div>

        <button
          className="create-lobby-button"
          onClick={createLobby}
          disabled={loading || !isBetAmountSelected}
        >
          {loading ? 'Creating Lobby...' : 'Create Lobby'}
        </button>

      </div>
    </div>
  );
};

export default BettingPage;
