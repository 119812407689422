import React, { createContext, useState, useEffect } from "react";
import { ethers } from "ethers";
import CustomAlert from "./components/CustomAlert"; // Ensure you import your CustomAlert component

// Create Wallet Context
export const WalletContext = createContext();

export const WalletProvider = ({ children }) => {
  const [walletAddress, setWalletAddress] = useState(null);
  const [alertMessage, setAlertMessage] = useState(""); // State to hold alert message

  // Load wallet address from localStorage when the component mounts
  useEffect(() => {
    const savedWalletAddress = localStorage.getItem("walletAddress");
    if (savedWalletAddress) {
      setWalletAddress(savedWalletAddress);
    }
  }, []);

  // Connect Wallet function
  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const address = await signer.getAddress();
        console.log("Connected Account:", address);
        setWalletAddress(address); // Update wallet address in state
        localStorage.setItem("walletAddress", address); // Save wallet address to localStorage

        // Show success alert using CustomAlert
        setAlertMessage("Wallet connected successfully!");
      } catch (error) {
        console.error(
          "User rejected the request or there was an error:",
          error
        );
        // Show error alert using CustomAlert
        setAlertMessage("User rejected the request or there was an error.");
      }
    } else {
      // Show warning alert using CustomAlert
      setAlertMessage("MetaMask not detected. Please install MetaMask.");
    }
  };

  // Disconnect Wallet function (optional)
  const disconnectWallet = () => {
    setWalletAddress(null);
    localStorage.removeItem("walletAddress");
    setAlertMessage("Wallet disconnected.");
  };

  return (
    <WalletContext.Provider
      value={{ walletAddress, connectWallet, disconnectWallet }}
    >
      {children}
      {/* Render custom alert if there is a message */}
      {alertMessage && (
        <CustomAlert
          message={alertMessage}
          duration={3000}
          onClose={() => setAlertMessage("")} // Clear alert message on close
        />
      )}
    </WalletContext.Provider>
  );
};
