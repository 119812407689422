import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ethers } from 'ethers';
import { WalletContext } from '../wallet'; // Import WalletContext
import Navbar from '../components/Navbar'; // Import Navbar
import CustomAlert from '../components/CustomAlert'; // Import CustomAlert
import '../styles/gamepage.css';


const GamePage = () => {
  const { lobby_id } = useParams();
  const { walletAddress, connectWallet } = useContext(WalletContext); // Get wallet address and connect function from context
  const [round, setRound] = useState(1);
  const [userScore, setUserScore] = useState(0);
  const [opponentScore, setOpponentScore] = useState(0);
  const [selectedMove, setSelectedMove] = useState('');
  const [submittedMove, setSubmittedMove] = useState('');
  const [opponentMove, setOpponentMove] = useState('');
  const [roundResult, setRoundResult] = useState('');
  const [timer, setTimer] = useState(60);
  const [gameOver, setGameOver] = useState(false);
  const [winner, setWinner] = useState(null);
  const [playerId, setPlayerId] = useState('');
  
  const [alertMessage, setAlertMessage] = useState(''); // State for custom alert
  const navigate = useNavigate();

  useEffect(() => {
    const getWalletAddress = async () => {
      if (walletAddress) {
        setPlayerId(walletAddress);
      } else {
        alert('Please connect your wallet.'); // Alert if wallet not connected
        navigate('/');
      }
    };

    getWalletAddress();
  }, [walletAddress, navigate]);

  const showAlert = (message) => {
    setAlertMessage(message);
    setTimeout(() => {
      setAlertMessage(''); // Clear alert after 3 seconds
    }, 3000);
  };

  const fetchGameState = useCallback(async () => {
    try {
      const response = await fetch(`https://e2mrgwhsw2.execute-api.ap-south-1.amazonaws.com/dev/game-state?lobby_id=${lobby_id}`);
      const data = await response.json();
      if (!data.error) {
        setRound(data.round || 1);
        setUserScore(data.user_score || 0);
        setOpponentScore(data.opponent_score || 0);
        setRoundResult(data.round_result || '');
        setOpponentMove(data.opponent_move || '');
        setWinner(data.winner || null);
        if (data.winner) {
          setGameOver(true);
        }
      } else {
        showAlert(data.error); // Use showAlert function
      }
    } catch (error) {
      console.error('Error fetching game state:', error);
      showAlert('Error fetching game state.'); // Use showAlert function
    }
  }, [lobby_id]);

  useEffect(() => {
    if (playerId && lobby_id) {
      const validatePlayer = async () => {
        try {
          const response = await fetch(`https://e2mrgwhsw2.execute-api.ap-south-1.amazonaws.com/dev/validate-player?lobby_id=${lobby_id}&player_id=${playerId}`);
          const data = await response.json();

          if (!data.valid) {
            showAlert('You are not authorized to join this game.'); // Use showAlert function
            navigate('/');
          } else {
            fetchGameState(); // Fetch the initial game state
          }
        } catch (error) {
          console.error('Error validating player:', error);
          showAlert('Error validating player.'); // Use showAlert function
          navigate('/');
        }
      };

      validatePlayer();
    }
  }, [lobby_id, playerId, navigate, fetchGameState]);

  const updateGameState = async (state) => {
    try {
      const response = await fetch('https://e2mrgwhsw2.execute-api.ap-south-1.amazonaws.com/dev/update-game-state', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(state),
      });
      const data = await response.json();
      if (data.error) {
        showAlert(data.error); // Use showAlert function
      }
    } catch (error) {
      console.error('Error updating game state:', error);
      showAlert('Error updating game state.'); // Use showAlert function
    }
  };

  const handleMoveChange = (move) => {
    setSelectedMove(move); // Set the selected move but do not submit
  };

  const determineRoundWinner = (userChoice, opponentChoice) => {
    if (userChoice === opponentChoice) return 'draw';
    if (
      (userChoice === 'stone' && opponentChoice === 'scissors') ||
      (userChoice === 'scissors' && opponentChoice === 'paper') ||
      (userChoice === 'paper' && opponentChoice === 'stone')
    ) {
      return 'user';
    } else {
      return 'opponent';
    }
  };

  const fetchOpponentMove = useCallback(async () => {
    try {
      const response = await fetch(`https://e2mrgwhsw2.execute-api.ap-south-1.amazonaws.com/dev/opponent-move?lobby_id=${lobby_id}&round=${round}`);
      const data = await response.json();
      return data.move || '';
    } catch (error) {
      console.error('Error fetching opponent move:', error);
      return '';
    }
  }, [lobby_id, round]);

  const submitMove = useCallback(async () => {
    if (gameOver || !selectedMove) return; // Ensure game is not over and a move is selected

    const opponentMove = await fetchOpponentMove();
    setOpponentMove(opponentMove);

    setSubmittedMove(selectedMove); // Submit the selected move
    const result = determineRoundWinner(selectedMove, opponentMove); // Use selectedMove directly
    setRoundResult(result);

    let updatedUserScore = userScore;
    let updatedOpponentScore = opponentScore;
    let finalWinner = null;

    if (result === 'user') {
      updatedUserScore += 1;
    } else if (result === 'opponent') {
      updatedOpponentScore += 1;
    }

    if (updatedUserScore === 2) {
      finalWinner = 'user';
      setWinner('user');
      setGameOver(true);
    } else if (updatedOpponentScore === 2) {
      finalWinner = 'opponent';
      setWinner('opponent');
      setGameOver(true);
    } else {
      setRound((prevRound) => prevRound + 1);
      setSelectedMove(''); // Reset selected move for next round
      setTimer(60); // Reset timer
    }

    await updateGameState({
      lobby_id: lobby_id,
      round: finalWinner ? round : round + 1,
      user_score: updatedUserScore,
      opponent_score: updatedOpponentScore,
      user_move: selectedMove,
      opponent_move: opponentMove,
      round_result: result,
      winner: finalWinner,
    });

    if (!finalWinner) {
      await fetchGameState();
    }
  }, [selectedMove, userScore, opponentScore, round, lobby_id, fetchOpponentMove, fetchGameState, gameOver]);

  const randomMove = () => {
    const moves = ['stone', 'paper', 'scissors'];
    return moves[Math.floor(Math.random() * moves.length)];
  };

  useEffect(() => {
    if (timer > 0 && !gameOver) {
      const countdown = setTimeout(() => setTimer(timer - 1), 1000);
      return () => clearTimeout(countdown);
    } else if (timer === 0 && !gameOver) {
      const randomSelectedMove = randomMove();
      setSelectedMove(randomSelectedMove); // Set random move as selectedMove
      submitMove();
    }
  }, [timer, gameOver, submitMove]);

  return (
    <div>
      <Navbar /> {/* Use the Navbar component */}

      {/* Display Custom Alert */}
      {alertMessage && (
        <CustomAlert 
          message={alertMessage} 
          duration={3000} 
          onClose={() => setAlertMessage('')} // Clear alert on close
        />
      )}

      <div className="game-container">
        {!gameOver ? (
          <div className="game-info">
            <h1 className="round-number">ROUND {round}</h1>

            <div className="round-results">
              <div className="result-box user">
                <p>You<br />{userScore}</p>
              </div>
              <div className="timer-circle">
                <div className="timer-text">{timer}</div> {/* Display seconds */}
              </div>
              <div className="result-box opponent">
                <p>Opponent<br />{opponentScore}</p>
              </div>
            </div>

            {roundResult && (
              <div className="move-images">
                <div className="move-image user" key={`user-${submittedMove}`}>
                  {submittedMove && <img src={`../assets/${submittedMove}.png`} alt="User's Move" />}
                </div>
                <div className="move-image opponent" key={`opponent-${opponentMove}`}>
                  {opponentMove && <img src={`../assets/${opponentMove}.png`} alt="Opponent's Move" />}
                </div>
              </div>
            )}

            <div className="move-grid">
              <div 
                className={`move-card ${selectedMove === 'stone' ? 'selected' : ''}`} 
                onClick={() => handleMoveChange('stone')}
              >
                <img src="../assets/stone.png" alt="Stone" />
                <p>Stone</p>
              </div>
              <div 
                className={`move-card ${selectedMove === 'paper' ? 'selected' : ''}`} 
                onClick={() => handleMoveChange('paper')}
              >
                <img src="../assets/paper.png" alt="Paper" />
                <p>Paper</p>
              </div>
              <div 
                className={`move-card ${selectedMove === 'scissors' ? 'selected' : ''}`} 
                onClick={() => handleMoveChange('scissors')}
              >
                <img src="../assets/scissors.png" alt="Scissors" />
                <p>Scissors</p>
              </div>
            </div>

            <button className="submit-move-btn" onClick={submitMove}>Submit Move</button>
          </div>
        ) : (
          <div className="game-over">
            <h2 className="game-over-text">Game Over</h2>
            <p className="game-over-text">{winner === 'user' ? 'YOU WON!' : 'OPPONENT WON!'}</p>
            <button className="btn-home" onClick={() => navigate('/')}>HOME</button>
            <button className="btn-claim" onClick={() => navigate('/Claim-Page')}>CLAIM</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default GamePage;
