// Home.js
import React from 'react';
import '../styles/styles.css'; 
//import '../styles/controllerstyle.css'; 
import '../styles/playstyle.css'; 
import controller from '../assets/controller.png';
import p1 from '../assets/p1.png'; 
import p2 from '../assets/p2.png';
import p3 from '../assets/p3.png';
import tokenomics from '../assets/tokenomics.png'; 
import aboutImage1 from '../assets/aboutImage1.png'; 
import aboutImage2 from '../assets/aboutImage2.png';
import aboutImage3 from '../assets/aboutImage3.png';
import game1Image from '../assets/game1.png'; // Add image paths
import game2Image from '../assets/game2.jpg';
import { useNavigate } from 'react-router-dom'; 
import Navbar from '../components/Navbar'; // Import the new Navbar component
import Footer from '../components/Footer';
const Home = () => {
    const navigate = useNavigate(); 

    const redirectToPlay = () => {
        navigate('/Play-Page'); 
    };

    return (
        <div>
            <Navbar /> {/* Use the Navbar component here */}

            <section className="hero">
                <h1>Welcome To WitsVerse</h1>
                <p>Discover a world of decentralized gaming tournaments.</p>
                <button className="btn-play-now" onClick={redirectToPlay}>PLAY</button>
            </section>

            {/* Updated WITS ARENA Section */}
            <section className="wits-arena">
                <h2>WITS ARENA</h2>
                <div className="game-cards">
                    <div className="card">
                        <div className="card-text">
                            <h3>ㅤ</h3>
                       <img src={game1Image} alt="GAME 1" className="game-image" />
                        <div className="card-text">
                        <button className="btn-play" onClick={() => navigate('/Lobby')}>PLAY</button>
                        </div>
                            
                            
                        </div>
                    </div>
                    <div className="card">
                        <h3>ㅤ</h3>
                        <img src={game2Image} alt="GAME 1" className="game-image" />
                    </div>
                    <div className="card">
                        <div className="card-text">
                            <h3>ㅤ</h3>
                            <img src={game2Image} alt="GAME 3" className="game-image" />
                            
                        </div>
                    </div>
                </div>
            </section>

            {/* TOKEN BENEFITS Section */}
            <section className="token-benefits">
                <h2>TOKEN BENEFITS</h2>
                <div className="benefit">
                    <img src={p1} alt="Benefit 1" className="benefit-image" />
                    <p className="benefit-text">Take advantage of reduced fees to increase the value and affordability of your wagers.</p>
                </div>
                <div className="benefit benefit-reverse">
                    <img src={p2} alt="Benefit 2" className="benefit-image" />
                    <p className="benefit-text">Play high stakes and earn new free tokens, giving you more opportunities to win big.</p>
                </div>
                <div className="benefit">
                    <img src={p3} alt="Benefit 3" className="benefit-image" />
                    <p className="benefit-text">Bet with WVT and help create a sustainable token economy since we will burn 10% of our fee.</p>
                </div>
            </section>

            {/* TOKENOMICS Section */}
            <section className="tokenomics-section">
                <h2 className="tokenomics-heading">TOKENOMICS</h2>
                <div className="tokenomics-card">
                    <img src={tokenomics} alt="Tokenomics" className="tokenomics-image" />
                    <div className="tokenomics-text">
                        <ul>
                            <li>Community (50%): Half of our tokens are dedicated to empowering users, reflecting our commitment to decentralization and community involvement.</li>

                            <li>Team (25%): A quarter of the tokens are allocated to the team, aligning their interests with WitsVerse's long-term growth and fostering innovation.</li>  

                            <li>Reserves (18.8%): These funds provide liquidity and stabilize token value, ensuring resilience against unforeseen challenges.</li>    

                            <li>Marketing (5%): This portion is allocated to strategic efforts that promote WitsVerse, enhancing visibility and market adoption.</li>    

                            <li>Liquidity Pool (1.2%): A small allocation ensures smooth transactions and ample liquidity for token holders.</li>   
                        </ul>
                    </div>
                </div>
            </section>

            {/* ABOUT US Section */}
            <section className="about-us">
                <h2>ABOUT US</h2>
                <div className="about-us-card">
                    <img src={aboutImage1} alt="About Us 1" className="about-image1" />
                    <div className="card-text">
                        <h3>OUR VALUES</h3>
                        <p>Dive into a world where your favorite game tournaments are enhanced by decentralized transactions. Enjoy a secure and seamless gaming experience just by connecting your crypto wallet. Join us at Wits Verse for fair play and rewarding adventures, anytime, anywhere. Let's game without limits!</p>
                    </div>
                </div>
                <div className="about-us-card">
                    <img src={aboutImage2} alt="About Us 2" className="about-image2" />
                    <div className="card-text">
                        <h3>OUR MISSION</h3>
                        <p>At Wits Verse, we aim to create an inclusive platform where players from all over the globe can compete in familiar, renowned PvP tournaments in a fair and transparent setting. While the game experience itself remains traditional, our use of blockchain technology ensures secure and decentralized transactions. By connecting your crypto wallet, you join a welcoming and connected global community.</p>
                    </div>
                </div>
                <div className="about-us-card">
                    <img src={aboutImage3} alt="About Us 3" className="about-image3" />
                    <div className="card-text">
                        <h3>OUR VISION</h3>
                        <p>Our vision is to transform the gaming industry with a platform that opens up gaming and financial rewards to everyone. We aim to build a strong, scalable space where users can participate in various tournaments, benefit from our cryptocurrency. With a focus on user satisfaction and transparency, we see Wits Verse becoming the premier destination for decentralized transactions in gaming.</p>
                    </div>
                </div>
            </section>

            <Footer/>
        </div>
    );
};

export default Home;
