import React, { useState, useEffect } from 'react';
import '../styles/alertstyle.css'; // Import CSS for styling the alert

const CustomAlert = ({ message, duration = 3000, onClose }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (message) {
      setIsVisible(true);
      const timer = setTimeout(() => {
        setIsVisible(false);
        if (onClose) {
          onClose();
        }
      }, duration);

      return () => clearTimeout(timer);
    }
  }, [message, duration, onClose]);

  return (
    <div className={`custom-alert ${isVisible ? 'visible' : ''}`}>
      {message}
    </div>
  );
};

export default CustomAlert;
