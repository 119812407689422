import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { WalletContext } from '../wallet';
import Navbar from '../components/Navbar';
import CustomAlert from '../components/CustomAlert';
import '../styles/lobbystyle.css';

const shortenAddress = (address) => {
  if (!address) return '';
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
};

const Lobby = () => {
  const { walletAddress, connectWallet } = useContext(WalletContext);
  const [lobbies, setLobbies] = useState([]);
  const [alertMessage, setAlertMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLobbies = async () => {
      try {
        const response = await fetch('https://e2mrgwhsw2.execute-api.ap-south-1.amazonaws.com/dev/lobbies');
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        console.log('Fetched lobbies:', data);
        setLobbies(data);
      } catch (error) {
        console.error('Error fetching lobbies:', error);
        showAlert(`Error fetching lobbies: ${error.message}`);
      }
    };

    fetchLobbies();
  }, []);

  const showAlert = (message) => {
    setAlertMessage(message);
    setTimeout(() => {
      setAlertMessage(''); // Clear the alert after 3 seconds
    }, 3000);
  };

  const handleView = async (lobby) => {
    if (!walletAddress) {
      showAlert('Please connect your wallet to proceed.');
      return;
    }

    try {
      const response = await fetch('https://e2mrgwhsw2.execute-api.ap-south-1.amazonaws.com/dev/pending-lobby', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          lobby_id: lobby.lobby_id,
          player_id: walletAddress,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        navigate(`/Payments/${lobby.lobby_id}`);
      } else {
        showAlert(data.error || 'Failed to set lobby to pending. Please try again.');
      }
    } catch (error) {
      console.error('Error setting lobby to pending:', error);
      showAlert('Error setting lobby to pending. See console for details.');
    }
  };

  return (
    <div className="lobby">
      <Navbar />

      {/* Display Custom Alert only if alertMessage is not empty */}
      {alertMessage && (
        <CustomAlert 
          message={alertMessage} 
          duration={3000} 
          onClose={() => setAlertMessage('')} // Clear alert on close
        />
      )}

      <div className="header-container">
        <h1 className="active-lobbies-title">ACTIVE LOBBIES</h1>
        <button className="create-lobby-button" onClick={() => navigate('/Create-Lobby')}>Create Lobby</button>
      </div>

      <div className="lobby-cards-container">
        {lobbies.length === 0 ? (
          <p>No lobbies available.</p>
        ) : (
          lobbies.map((lobby) => (
            <div key={lobby.lobby_id} className="lobby-card">
              <img src={`assets/pfps/pfp${(lobbies.indexOf(lobby) % 10) + 1}.jpg`} alt="Profile" className="pfp" />
              <div className="lobby-card-content">
                <p>Currency: {lobby.currency}</p>
                <p>Amount: {lobby.amount}</p>
                <p>Creator: {shortenAddress(lobby.creator_id)}</p>

                {lobby.player_id && <p>Player: {shortenAddress(lobby.player_id)}</p>}

                <button
                  className="mini-copy-button"
                  onClick={() => navigator.clipboard.writeText(lobby.creator_id)}
                  aria-label="Copy creator address"
                >
                  <img src="assets/copy-icon.png" alt="Copy" />
                </button>
                <button className="view-button" onClick={() => handleView(lobby)}>
                  View
                </button>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Lobby;
