import React, { useState } from 'react';
import '../styles/styles.css'; 
import '../styles/rulesstyle.css'; 
import Navbar from '../components/Navbar'; // Import the Navbar component
import generalRulesImage from '../assets/rules.png'; // Import general rules image
import spsRulesImage from '../assets/rules_sps.png'; // Import Stone Paper Scissors rules image


const Rules = () => {
    const [expandedCard, setExpandedCard] = useState(null);

    const toggleCard = (card) => {
        setExpandedCard(expandedCard === card ? null : card);
    };

    return (
        <div>
            <Navbar /> 

            <section className="content">
                <h1>Rules</h1>
                <div className="rules-columns">
                    <div
                        className={`rules-card ${expandedCard === 'general' ? 'expanded' : ''}`}
                        onClick={() => toggleCard('general')}
                    >
                        <img src={generalRulesImage} alt="General Rules" className="rules-image" />
                        <h2>General Rules</h2>
                        {expandedCard === 'general' && (
                            <ol className="rules-list">
                                <li>A fee of only 5% of the pot will be charged when using Matic.</li>
                                <li>Enjoy a low fee of just 2% of the pot when using WVT.</li>
                                <li>10% of the fees collected in WVT will be burned, reducing the total supply.</li>
                                <li>Earn 100 WVT for participating in high-stakes games with Matic.</li>
                                <li>Earn 50 WVT for participating in high-stakes games with WVT.</li>
                            </ol>
                        )}
                    </div>

                    <div
                        className={`rules-card ${expandedCard === 'sps' ? 'expanded' : ''}`}
                        onClick={() => toggleCard('sps')}
                    >
                        <img src={spsRulesImage} alt="Stone Paper Scissors Rules" className="rules-image" />
                        <h2>Stone, Paper & Scissors</h2>
                        {expandedCard === 'sps' && (
                            <>
                                <h3>Rules for Creating a Lobby:</h3>
                                <ol className="rules-list">
                                    <li>Create a Lobby: Initiate a lobby to invite other players to join.</li>
                                    <li>Set an Entry Amount: Specify the amount of WVT tokens to create the lobby. This amount will act as the entry fee for other players.</li>
                                    <li>6 Choices for Draw Scenarios: Provide 6 additional choices to resolve potential draw scenarios during the game.</li>
                                </ol>

                                <h3>Rules for Joining a Lobby:</h3>
                                <ol className="rules-list">
                                    <li>Match the Entry Fee: To join an existing lobby, you must pay the same entry amount that the lobby creator has set.</li>
                                    <li>Winning Criteria: The first player to win 2 rounds is declared the winner.</li>
                                    <li>Time Limit per Move: Players have a 1-minute timer to select their move. If no move is made within this time, a random move will be auto-selected.</li>
                                    <li>Exhausted Choices: If all 6 pre-selected moves are used up, a random move will be automatically assigned.</li>
                                    <li>Winning and Claims: The winner takes the entire pot. The winnings can be claimed from the Claim Page on the Wits Verse platform.</li>
                                    <li>Disconnection Penalty: If an existing player shuts down their browser or exits the game during the match, the entire pot will be awarded to the opponent.</li>
                                </ol>
                            </>
                        )}
                    </div>
                </div>
            </section>
            
        </div>
    );
};

export default Rules;
