import React from 'react';
import '../styles/footerstyle.css'; 

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-content">
          <div className="footer-logo">
            <img src="/assets/logo.png" alt="WitsVerse Logo" />
          </div>
          <ul className="footer-links">
            <li className="footer-icon twitter">
              <a href="https://twitter.com/witsverse" target="_blank" rel="noopener noreferrer">
                <img src="/assets/twitter-icon.png" alt="Twitter" />
              </a>
            </li>
            <li className="footer-icon instagram">
              <a href="https://instagram.com/witsverse" target="_blank" rel="noopener noreferrer">
                <img src="/assets/instagram-icon.png" alt="Instagram" />
              </a>
            </li>
            <li className="footer-icon gmail">
              <a href="mailto:contact@witsverse.com">
                <img src="/assets/gmail-icon.png" alt="Contact Us" />
              </a>
            </li>
          </ul>
        </div>

        {/* Navigation Links in the Center */}
        <nav className="footer-nav">
          <ul className="nav-links">
            <li><a href="/">Home</a></li>
            <li><a href="/Play-Page">Play</a></li>
            <li><a href="/Rules-Page">Rules</a></li>
            <li><a href="/Faq-Page">FAQ</a></li>
            <li><a href="/Claim-Page">Claim</a></li>
          </ul>
        </nav>
      </div>
    </footer>
  );
}

export default Footer;
